import { PDFDocument, StandardFonts } from 'pdf-lib';
import { WEB_SERVER_URL } from "../../utils/constants";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useState } from 'react';


const CertificateForm = ({loggedUser, setHideUpload}) => {
  const [uri, setUri] = useState();
  const [boxHide, setBoxHide] = useState(true);

  const modifyPdf = async () =>  {
    setHideUpload(true);
    const url = './adoptaplant-certificate.pdf';
    
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
  
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    //const { width, height } = firstPage.getSize()
    firstPage.drawText(loggedUser?.firstName + ' ' + loggedUser?.lastName, {
      x: 290,
      y: 250,
      size: 30,
      font: helveticaFont, 
    });

    const iframeUri = await pdfDoc.saveAsBase64({dataUri: true});
    setUri(iframeUri);
    setBoxHide(false);
      
    const pdfBytes = await pdfDoc.save();
    // download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");
  }


  return (
    <>
    <Box hidden={boxHide}>
       <iframe src={uri} width={850} height={700}></iframe>
    </Box>
     <Box hidden={!boxHide}
          > 
            <Button
              type="button"
              sx={{
                      width: 247,
                      height: 50,
                      backgroundColor: "#F9A31A",
                      color: "#FFFFFF",
                      "&:hover": { color: "#FFFFFF", backgroundColor: "#F9A31A" }
                    }}
              onClick={modifyPdf}
              
            >
              {" "}
              Get Your Certificate
            </Button>
          </Box>
    </>
  )

}

export default CertificateForm;