import { useNavigate, Link} from 'react-router-dom';
import { useEffect,useState } from 'react';
function Navbar() {
    const [scrolled, setScrolled] = useState(false);
    let loggedUser = localStorage.getItem("user");
    const [isLogged, setIsLogged] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        window.onscroll = function() {
          if (window.scrollY > 10) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        };
      }, []);

      useEffect(() => {
        if (loggedUser) {
           setIsLogged(true);
        }
      }, [loggedUser]);

     


        return (
                // ${ scrolled && "scrolled"}
                <nav className={` nav scrolled   `}>
                        <div className='container d-flex justify-content-between'>
                        <img className='nav__logo' src="../assets/images/yujminds.png" onClick={() => {navigate("/");}} alt="logo"></img>
                                {!isLogged && 
                                <div className='d-flex align-items-center nav__buttons-wrapper'>
                                        {/* <Link to="/register" className='primary-btn'>
                                                SignUp
                                        </Link> */}
                                        <Link to="/login" className='primary-btn primary-btn--border'>
                                                Log In 
                                        </Link>                                        
                                </div>
                                }
                                {isLogged && 
                                <div className='d-flex align-items-center nav__buttons-wrapper'>
                                        <Link to="/signout" className='primary-btn'>
                                                SignOut
                                        </Link>                                       
                                </div>
                                }
                        </div>
                </nav>);
}

export default Navbar;