import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link } from "react-router-dom";
const registerSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  rollNumber: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  // address: yup.string().required("required"),
  school: yup.string().required("required"),
  standard: yup.string().required("required"),
  division: yup.string().required("required"),
  plant: yup.string().required("required"),
});

const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  school: "",
  plant: "",
  rollNumber: "",
  standard: "",
  division: "",
};

const stdOptions = [{label: 10, value: 10}, {label: 9, value: 9}, {label: 8, value: 8}, {label: 7, value: 7}, {label: 6, value: 6}];
const divOptions = [{label: 'A', value: 'A'}, {label: 'B', value: 'B'}, {label: 'C', value: 'C'}, {label: 'D', value: 'D'}];

const RegisterForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [schoolsOptions, setSchoolsOptions] = useState([]);
  const [plantsOptions, setPlantsOptions] = useState([]);

  const populateSchools = () => {
    let schools = props.schoolsData;
    let schoolsOptionItems = [];
    if (schools && schools.length > 0) {
      schools.forEach(function (value, index) {
        schoolsOptionItems.push({
          label: value.institutionName,
          value: value.id,
        });
      });
      setSchoolsOptions(schoolsOptionItems);
    }
  };

  const populatePlants = () => {
    let plants = props.plantsData;
    let plantsOptionItems = [];
    if (plants && plants.length > 0) {
      plants.forEach(function (value, index) {
        plantsOptionItems.push({
          label: value.plantName,
          value: value.id,
        });
      });
      setPlantsOptions(plantsOptionItems);
    }
  };

  useEffect(() => {
    populateSchools();
    populatePlants();
  }, [props.schoolsData, props.plantsData]);

  const handleFormSubmit = (values) => {
  
    let obj = new FormData();
    obj.append("firstName", values.firstName);
    obj.append("lastName", values.lastName);
    obj.append("school", values.school);
    obj.append("phone", values.phone);
    obj.append("email", values.email);
    obj.append("address", values.address);
    // obj.append("file", values.file);
    obj.append("plant", values.plant);    
    obj.append("div", values.division);   
    obj.append("std", values.standard);   
    obj.append("rollNumber", values.rollNumber);   

    if (values.dateOfBirth) {
      const dob = new Date(values.dateOfBirth);
      const dateOfBirthVal =  dob.getFullYear() + '-' + ("0" + (dob.getMonth() + 1)).slice(-2) + '-' + ("0" + dob.getDate()).slice(-2);
      obj.append("dateOfBirth", dateOfBirthVal);    
    }
    props.onSubmit(obj);
  };
  
  return (
    <Formik
      initialValues={initialValuesRegister}
      onSubmit={(values, {resetForm}) => {
           handleFormSubmit(values);
           resetForm()
      }}
      validationSchema={registerSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue
      }) => (
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <div className="primary-box">

            <h2 className="title"> Register for your plant Certificate </h2>
            <p className="sub-title">Please fill in the below details to issue you certificate </p>
            <div className="form">
              <div className="form-content">

              <div className="form-content__inputs">
                  <label className="label">First Name<span className="spanlabel"> *</span></label>
                  <div>
                    <TextField
                   
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      name="firstName"
                      error={Boolean(touched.firstName) && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      fullWidth
                      size="small"
                    />
                  </div>
                </div>

                <div className="form-content__inputs">
                  <label className="label">Last Name<span className="spanlabel"> *</span></label>
                  <div>
                    <TextField
                     
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      name="lastName"
                      error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      fullWidth
                        size="small"
                    />
                  </div>
                </div>


                

                <div className="form-content__inputs">
                  <label className="label">Select School<span className="spanlabel"> *</span></label>
                  <div>
                    <TextField
                      id="outlined-select"
                      select                
                      name="school"
                      value={values.school}
                      onChange={handleChange}
                      defaultValue=""
                      fullWidth
                      size="small"
                      error={Boolean(touched.school) && Boolean(errors.school)}
                      helperText={touched.school && errors.school}
                    >
                      {schoolsOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <div className="form-content__inputs">
                  <label className="label">Select Standard<span className="spanlabel"> *</span></label>
                  <div>
                    <TextField
                      id="outlined-select"
                      select
                      name="standard"
                      value={values.standard}
                      onChange={handleChange}
                      defaultValue=""
                      fullWidth
                      size="small"
                      error={Boolean(touched.standard) && Boolean(errors.standard)}
                      helperText={touched.standard && errors.standard}
                    >
                      {stdOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <div className="form-content__inputs">
                  <label className="label">Select Division<span className="spanlabel"> *</span></label>
                  <div>
                    <TextField
                      id="outlined-select"
                      select
                      name="division"
                      value={values.division}
                      onChange={handleChange}
                      defaultValue=""
                      fullWidth
                      size="small"
                      error={Boolean(touched.division) && Boolean(errors.division)}
                      helperText={touched.division && errors.division}
                    >
                      {divOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="form-content__inputs">
                  <label className="label">Roll Number<span className="spanlabel"> *</span></label>
                  <div>

                    <TextField
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.rollNumber}
                      name="rollNumber"
                      error={Boolean(touched.rollNumber) && Boolean(errors.rollNumber)}
                      helperText={touched.rollNumber && errors.rollNumber}
                      sx={{ gridColumn: "span 2" }}
                      inputProps={{
                        maxLength: 5,
                      }}
                      fullWidth
                      size="small"
                    />
                  </div>
                </div>
                <div className="form-content__inputs">
                  <label className="label">Select Plant<span className="spanlabel"> *</span></label>
                  <div>
                    <TextField
                      id="outlined-select"
                      select
                      name="plant"
                      value={values.plant}
                      onChange={handleChange}
                      defaultValue=""
                      fullWidth
                      size="small"
                      error={Boolean(touched.plant) && Boolean(errors.plant)}
                      helperText={touched.plant && errors.plant}
                    >
                      {plantsOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="form-content__inputs">
                  <label className="label">Phone</label>
                  <div>
                    <TextField
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      name="phone"
                      error={Boolean(touched.phone) && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                      fullWidth
                        size="small"
                    />
                  </div>
                </div>
                <div className="form-content__inputs">
                  <label className="label">Email</label>
                  <div>
                    <TextField
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={Boolean(touched.email) && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      fullWidth
                      size="small"
                    />
                  </div>
                </div>

                <div className="form-content__inputs">
                  <label className="label">Address</label>
                  <div>
                    <TextField
                      type="textarea"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      name="address"
                      error={Boolean(touched.address) && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      fullWidth
                        size="small"
                    />
                  </div>
                </div>
                <div className="form-content__inputs">
                  <label className="label">Select Date Of Birth</label>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div>
                        <DatePicker
                          disableFuture
                          format="DD/MM/YYYY"
                          value={values.dateOfBirth}
                          onChange={(value) => setFieldValue("dateOfBirth", Date.parse(value), true)}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              error: touched.dateOfBirth && Boolean(errors.dateOfBirth),
                              helperText: touched.dateOfBirth && errors.dateOfBirth
                            }
                          }}
                          fullWidth
                          size="small"
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            <p>Fields marked with <span className="spanlabel">*</span> are mandatory</p>
            <div className="text-center">
              <div className="form-action">
                <Button
                  type="submit"
                  sx={{
                    width: 247,
                    height: 50,
                    backgroundColor: "#F9A31A",
                    color: "#FFFFFF",
                    "&:hover": { color: "#FFFFFF", backgroundColor: "#F9A31A" }
                  }}
                >
                  Sign Up
                </Button>
                <div className="register-link">
                  <span className="txt">Already have an Account? </span>
                  <Link to="/login" className="link">LogIn</Link>
                </div>
              </div>
            </div>

          </div>

        </form>
      )}
    </Formik>
  );
};

export default RegisterForm;