import axios from "../utils/axios";

class InstitutionService {
    getInstitutions() {
        return axios({
        method: "GET",
        url: `/institutions`
        });
    }
}

export default new InstitutionService();
