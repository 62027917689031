import {
  Button,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import CertificateForm from "../Certificate/CertificateForm";
import { API_SERVER_BASE_URL } from "../../utils/constants";

const registerSchema = yup.object().shape({

});

const initialValuesRegister = {
};

const UploadForm = (props) => {
  let loggedUser = localStorage.getItem("user");
  let [image1, setImage1] = useState(null);
  let [image2, setImage2] = useState(null);
  let [hideUpload, setHideUpload] = useState(false);

  const logOut = () => {
    props.logOut();
  }

  if (loggedUser) {
    loggedUser = JSON.parse(loggedUser);
  } else {
    logOut();
  }

   const handleFormSubmit = (values) => {
    let obj = new FormData();
    obj.append("referenceId", loggedUser?.referenceId);
    obj.append("file1", values.file1);   
    obj.append("file2", values.file2);   
    props.onUpload(obj);
  };


  const onImageChange = (event, file) => {
    if (event.target.files && event.target.files[0]) {
      (file === 1) ? setImage1(URL.createObjectURL(event.target.files[0])) : setImage2(URL.createObjectURL(event.target.files[0]));
    }
  }
  
  console.log(image1);
  console.log(image2);

  console.log(image1);
  console.log(image2);

  return (
    <Formik
      initialValues={initialValuesRegister}
      onSubmit={(values, {resetForm}) => {
           handleFormSubmit(values);
           resetForm()
      }}
      validationSchema={registerSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue
      }) => (
        <div className="container">
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="primary-box">
              <div hidden={hideUpload}>
              <h2 className="title"> Upload </h2>
              <p className="sub-title">Dear {loggedUser?.firstName} {loggedUser?.lastName},
              {!props?.usersObj?.isPictureVerified && !props?.usersObj?.photoUrlFinal &&
               ` Please upload your plant pictures.`
              }
              {!props?.usersObj?.isPictureVerified && props?.usersObj?.photoUrlFinal &&
               ` Your uploaded plant pictures.`
              }
              {props?.usersObj?.isPictureVerified && 
                ` Please click get certificate button.`
               }</p>
              <div className="form">
                <div className="form-content">
                  <div className="form-content__inputs">
                  {props?.usersObj?.photoUrl &&
                    <img alt="preview" src={ API_SERVER_BASE_URL + props?.usersObj?.photoUrl} width="100" 
                    height="100"/>
                  }  
                  {!props?.usersObj?.photoUrl && !image1 &&
                    <div>
                      <label className="label">Select the initial picture of the plant</label>
                      <div>                      
                        <input id="file1" name="file1" type="file" accept="image/*" onChange={(event) => {
                          setFieldValue("file1", event.currentTarget.files[0]);
                          onImageChange(event, 1);
                        }} />                                        
                      </div>
                    </div>
                    }    
                    {!props?.usersObj?.photoUrl && image1 && 
                        <img alt="preview" src={image1} width="100"
                        height="100"/>
                    }
                  </div>                 
                </div>
                <div className="form-content">                  
                  <div className="form-content__inputs">
                  {props?.usersObj?.photoUrlFinal &&
                     <img alt="preview" src={API_SERVER_BASE_URL + props?.usersObj?.photoUrlFinal} width="100"
                              height="100"/>   
                  }
                                     
                      <div>
                      {!props?.usersObj?.photoUrlFinal && !image2 &&
                        <div>
                          <label className="label">Select the latest picture of the plant</label>   
                          <input id="file2" name="file2" type="file" accept="image/*" onChange={(event) => {
                            setFieldValue("file2", event.currentTarget.files[0]);
                              onImageChange(event, 2);
                          }} />
                        </div>
                      }
                      {!props?.usersObj?.photoUrlFinal && image2 && 
                          <img alt="preview" src={image2} width="100"
                          height="100"/>
                      }
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="form-action">
                    {(!props?.usersObj?.photoUrl || !props?.usersObj?.photoUrlFinal) && (image1 || image2) &&
                      <Button
                        type="submit"
                        sx={{
                          width: 247,
                          height: 50,
                          backgroundColor: "#F9A31A",
                          color: "#FFFFFF",
                          "&:hover": { color: "#FFFFFF", backgroundColor: "#F9A31A" }
                        }}
                      >
                        Upload
                      </Button>
                    }                    
                  </div>
                </div>
              </div>
              </div>
              {props?.usersObj?.isPictureVerified && 
                <div >
                  <CertificateForm
                    loggedUser={loggedUser}
                    setHideUpload={setHideUpload}
                  />
                </div>
              }
            </div>
            <div className="spacer"></div>
            
          </form>
          
        </div>        
      )}
    </Formik>
  );
};

export default UploadForm;