import axios from "../utils/axios";

class UserService {

  register(data) {
    return axios({
      method: "POST",
      url: `/users/register`,
      // headers: { "Content-Type": "multipart/form-data" },
      data,
    });
  }
    
  signIn(data) {
    return axios({
      method: "POST",
      url: `/users/signin`,
      data,
    });
  }

  uploadPlantsPictures(data) {
    return axios({
      method: "POST",
      url: `/users/upload-plant`,
      headers: { "Content-Type": "multipart/form-data" },
      data,
    });
  }

  login(data) {
    return axios({
      method: "POST",
      url: `/users/login`,
      data,
    });
  }

  getStudentUsers(id, sch, std, div, roll) {
    return axios({
      method: "GET",
      url: `/users/type/${id}/${sch}/${std}/${div}/${roll}`,
    });
  }

  verifyImages(data) {
    return axios({
      method: "POST",
      url: `/users/verify-images`,
      data
    })
  }

  getUserByRefId(refId) {
    return axios({
      method: "GET",
      url: `/users/reference/${refId}`,
    });
  }

}
export default new UserService();
