import {
  Button,
  TextField,
  MenuItem
} from "@mui/material";
import { Formik} from "formik";
import React, { useEffect, useState } from "react";
import { API_SERVER_BASE_URL } from "../../../utils/constants";

const initialValuesSignIn = { 
  school: "",
  rollNumber: "",
  standard: "",
  division: "",
};

const stdOptions = [{label: 10, value: 10}, {label: 9, value: 9}, {label: 8, value: 8}, {label: 7, value: 7}, {label: 6, value: 6}];
const divOptions = [{label: 'A', value: 'A'}, {label: 'B', value: 'B'}, {label: 'C', value: 'C'}, {label: 'D', value: 'D'}];


const VerifyImagesForm = (props) => {
  let loggedUser = localStorage.getItem("user");
  const [userIds, setUserIds] = useState([]);
  const [schoolsOptions, setSchoolsOptions] = useState([]);

  const populateSchools = () => {
    let schools = props.schoolsData;
    let schoolsOptionItems = [];
    if (schools && schools.length > 0) {
      schools.forEach(function (value, index) {
        schoolsOptionItems.push({
          label: value.institutionName,
          value: value.id,
        });
      });
      setSchoolsOptions(schoolsOptionItems);
    }
  };

  useEffect(() => {
    populateSchools();
  }, [props.schoolsData]);

  useEffect(() => {
    if (props.usersObj) {   
      const newUsers = props.usersObj.filter(t => t.isPictureVerified === true);      
      let result = newUsers.map(a => a.id);      
      setUserIds(result);      
    }
  }, [props.usersObj]);

  const logOut = () => {
    props.logOut();
  }

  if (loggedUser) {
    loggedUser = JSON.parse(loggedUser);
  } else {
    logOut();
  }

  const handleSerachFormSubmit= (values) => {
    let obj = {
      sch: values.school?  values.school : 0,
      std: values.standard ?  values.standard : 0,
      div: values.division ?  values.division : 0,
      roll: values.rollNumber ?  values.rollNumber : 0,
    };  
    props.onSearch(obj);
  }

  const handleFormSubmit = (values) => {
    const obj = {verifyIds: userIds }
    props.onVerify(obj);
  };

  const selectUsersToVerify = (e) => {
    const selectedId = parseInt(e.target.value);
    if (userIds.includes(selectedId)) {
      // unchecked
      const newIds = userIds.filter((id) => id !== selectedId);
      setUserIds(newIds);
    } else {
      // checked
      const newIds = [...userIds];
      newIds.push(selectedId);
      setUserIds(newIds);
    }
  };
 
  return (
    <>
    <div>
    <Formik
      initialValues={initialValuesSignIn}
      onSubmit={(values, {resetForm}) => {
           handleSerachFormSubmit(values);
           resetForm()
      }}
      validationSchema={""}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => (
        <div className="container">
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="primary-box">
              <h2 className="title"> Verify Uploaded Images </h2>
              <p className="sub-title"> Search </p>
              <div className="form">
                <div className="search-form-content">
                  <div className="search-form-content__inputs">
                    <label className="search-label">School</label>
                    <div>
                      <TextField
                        id="outlined-select"
                        select
                        name="school"
                        value={values.school}
                        onChange={handleChange}
                        defaultValue=""
                        fullWidth
                        size="small"
                      >
                        {schoolsOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                  <div className="search-form-content__inputs">
                    <label className="search-label">Standard</label>
                    <div>
                      <TextField
                        id="outlined-select"
                        select
                        name="standard"
                        value={values.standard}
                        onChange={handleChange}
                        defaultValue=""
                        fullWidth
                        size="small"
                      >
                        {stdOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                  <div className="search-form-content__inputs">
                    <label className="search-label">Division</label>
                    <div>
                      <TextField
                        id="outlined-select"
                        select
                        name="division"
                        value={values.division}
                        onChange={handleChange}
                        defaultValue=""
                        fullWidth
                        size="small"
                      >
                        {divOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div className="search-form-content">                  
                  <div className="search-form-content__inputs">
                    <label className="search-label">Roll Number</label>
                    <div>
                      <TextField
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rollNumber}
                        name="rollNumber"
                        error={Boolean(touched.rollNumber) && Boolean(errors.rollNumber)}
                        helperText={touched.rollNumber && errors.rollNumber}
                        sx={{ gridColumn: "span 2" }}
                        inputProps={{
                          maxLength: 5,
                        }}
                        fullWidth
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="search-form-content__inputs">
                    {/* <div className="form-action"> */}
                      <Button
                        type="submit"
                        sx={{
                          width: 100,
                          height: 25,
                          backgroundColor: "#F9A31A",
                          color: "#FFFFFF",
                          "&:hover": { color: "#FFFFFF", backgroundColor: "#F9A31A" }
                        }}
                      >
                        Search
                      </Button>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
    </div>
    <Formik
      initialValues={""}
      onSubmit={(values, {resetForm}) => {
           handleFormSubmit(values);
           resetForm()
      }}
      validationSchema={""}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue
      }) => (
        <div className="container">
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="primary-box">
               <div className="form">
                {props.usersObj && props.usersObj.length > 0 && 
                  props.usersObj.map((rec) => (
                    <div className="form-content">
                      <div className="form-content__inputs">  
                      <label className="label">{rec.firstName} {rec.lastName}</label>
                            <img alt="preview" src={API_SERVER_BASE_URL + rec.photoUrl} width="100" 
                            height="100"/>
                            <img alt="preview" src={API_SERVER_BASE_URL + rec.photoUrlFinal} width="100"
                              height="100"/>     
                      </div>   
                      <div className="verify">
                        <input 
                          type="checkbox"
                          value={rec.id}
                          onChange={(e) => {
                            selectUsersToVerify(e);
                          }}
                          checked={
                            userIds.includes(rec.id) ? true : false
                          }
                        />
                      </div>              
                    </div>
                    )
                  )
                }  
                {props.usersObj && props.usersObj.length > 0 &&             
                <div className="text-center">
                  <div className="form-action">
                    <Button
                      type="submit"
                      sx={{
                        width: 247,
                        height: 50,
                        backgroundColor: "#F9A31A",
                        color: "#FFFFFF",
                        "&:hover": { color: "#FFFFFF", backgroundColor: "#F9A31A" }
                      }}
                    >
                      Verify
                    </Button>                    
                  </div>
                </div>
                }
              </div>
            </div>
            <div className="spacer"></div>
          </form>
        </div>        
      )}
    </Formik>
    </>
  );
};

export default VerifyImagesForm;