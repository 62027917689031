import axios from "../utils/axios";

class PlantService {
  getPlants() {
    return axios({
      method: "GET",
      url: `/plants`
    });
  }
}
export default new PlantService();
