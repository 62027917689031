
import React, { useEffect } from "react";
import SignInForm from "./SignInForm";
import { signIn, SIGNIN_ERROR } from "../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { getInstitutions } from "../../redux/actions/InstitutionActions";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schoolsData = useSelector((state) => state.institution.data);

  useEffect(() => {
      dispatch(getInstitutions());
  }, []);

  const onLogin = (obj) => {
      dispatch(signIn(obj)).then((res) => {
        if (res?.type === SIGNIN_ERROR) {
          alert(res?.payload?.data?.message);
        } else {
          navigate("/upload");
        }   
      });    
  };

  return (
    <div>
      <SignInForm
        onLogin={onLogin}
        schoolsData={schoolsData}
      />
    </div>
  );
}
