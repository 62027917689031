import {  
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SIGNIN_LOADING,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR, 
  UPLOAD_PLANT_LOADING,
  UPLOAD_PLANT_SUCCESS,
  UPLOAD_PLANT_ERROR,
  USERTYPE_LOADING,
  USERTYPE_SUCCESS,
  USERTYPE_ERROR,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_ERROR
} from "../actions/UserActions";

const initialState = {
  success: false,
  loading: false,
  error: null,
  data: null
};

const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_LOADING:
    case SIGNIN_LOADING:
    case UPLOAD_PLANT_LOADING:
    case USERTYPE_LOADING:
    case GET_USER_LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        data: null,
      };
    }    
    case REGISTER_SUCCESS:
    case SIGNIN_SUCCESS: 
    case UPLOAD_PLANT_SUCCESS:
    case USERTYPE_SUCCESS:
    case GET_USER_SUCCESS: {
      return {
        ...state,
        success: true,
        data: action.payload,
        loading: false,
        error: null,
      };
    }    
    case REGISTER_ERROR:
    case SIGNIN_ERROR: 
    case UPLOAD_PLANT_ERROR: 
    case USERTYPE_ERROR:
    case GET_USER_ERROR: {
      return {
        success: false,
        loading: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default UserReducer;
