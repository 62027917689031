import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import PlantReducer from "./PlantReducer";
import InstitutionReducer from "./InstitutionReducer";

const appReducer = combineReducers({
  user: UserReducer,
  plant: PlantReducer,
  institution: InstitutionReducer
});

const RootReducer = (state, action) => {
  return appReducer(state, action);
};
export default RootReducer;
