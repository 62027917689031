
import React, { useEffect, useState } from "react";
import UploadForm from "./UploadForm";
import { uploadPlantsPictures } from "../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut, getUserByRefId } from "../../redux/actions/UserActions";

export default function Upload() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem("user")));
  console.log(loggedUser);
  const usersObj = useSelector((state) => state.user?.data);
  
  // useEffect(() => {
  //   setLoggedUser(localStorage.getItem("user"));
  // }, []);
  
  useEffect(() => {
    if (loggedUser?.referenceId) {
      dispatch(getUserByRefId(loggedUser.referenceId));   
    }    
  }, [loggedUser]);

  const onUpload = (obj) => {
    dispatch(uploadPlantsPictures(obj)).then((res) => {
      window.location.reload();
    });    
  };

  const logOut = () => {
    dispatch(signOut()).then((res) => {
      navigate("/login");
    });    
};

  return (
    <div>
      <UploadForm
        onUpload={onUpload}
        logOut={logOut}
        usersObj={usersObj}
      />
    </div>
  );
}
