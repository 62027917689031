
const Thankyou = () => {
    return (
      <div className="primary-box">
        
        <h2 className="title"> Thank You </h2>
            <p className="sub-title">Thank you for signing up. Great! You have adopted a plant.</p>
            <br></br>
            <p className="label">You can now login <a href="/login">here.</a></p>
      </div>
    )
  }
  
  export default Thankyou;