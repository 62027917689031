
import React, { useEffect } from "react";
import LoginForm from "./LoginForm";
import { login, LOGIN_ERROR } from "../../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
      
  }, []);

  const onLogin = (obj) => {
      dispatch(login(obj)).then((res) => {
        if (res?.type === LOGIN_ERROR) {
          alert(res?.payload?.data?.message);
        } else {
          navigate("/admin/verifyImages");
        }   
      });    
  };

  return (
    <div>
      <LoginForm
        onLogin={onLogin}
      />
    </div>
  );
}
