
import { useNavigate, Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import React, { useState } from "react";


const Home = () => {
  let loggedUser = localStorage.getItem("user");
  let btnText = (loggedUser) ? 'Get your Certificate' : 'Sign Up to Get your Certificate';
  const [varDisplay, setVarDisplay] = useState("none");

  const openRightMenu = () => { 
    setVarDisplay("block");
  }
  
  const closeRightMenu = () => {
    setVarDisplay("none");
  }

  return (

    //#HOMEPAGE


    <div className='hero'>
       <button className="w3-button w3-teal w3-xlarge w3-right" onClick={() => {openRightMenu();}} >&#9776;</button>
     
      <div className='container'>
        <div className='d-lg-flex'>
          <div className='hero__left'>
            <h1 className='hero__title'>Welcome to <strong>Adopt a Plant</strong></h1>
            <p className='hero__text'>Adopt &bull; Care &bull; Love</p>
          </div>

          {/* ====#RIGHT CONTENT==== */}
          <div className='hero__right'>
            <p className='hero__text'>Yujminds is an association that brings together people who are not indifferent to the fate of our planet and would like to some how contribute to improving its situation. <strong>Are you one of them?</strong></p>
            
            <Link to="/register" className="primary-btn">{btnText}</Link>
          </div>

        </div>

        <div className='slider'>
          <Carousel showThumbs={false} showStatus={false}>
            <div>
              <img className='img-fluid' src="../assets/images/sliderOne.jpg" alt="plants"/>
            </div>
            <div>
              <img className='img-fluid' src="/assets/images/sliderTwo.jpg" alt="mission"/>
            </div>
            <div>
              <img className='img-fluid' src="/assets/images/sliderThree.jpg"  alt="students" />
            </div>
          </Carousel>
        </div>

      </div>
      <div className="w3-sidebar w3-bar-block w3-card w3-animate-right" style={{display: varDisplay }} >
        <button onClick={() => {closeRightMenu();}}  className="w3-bar-item w3-button w3-large">Close &times;</button>
        <div className="row">
          <div className="column">
            <img src="/assets/advertisementImages/adv1.jpg" alt="Ad1" width="180" height="120"/>
            <img src="/assets/advertisementImages/adv2.jpg" alt="Ad2" width="180" height="120"/>
            <img src="/assets/advertisementImages/adv3.jpg" alt="Ad3" width="180" height="120"/>
            <img src="/assets/advertisementImages/adv4.jpg" alt="Ad4" width="180" height="120"/>            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;