import {  
  PLANTS_LOADING,
  PLANTS_SUCCESS,
  PLANTS_ERROR,
 
} from "../actions/PlantActions";

const initialState = {
  success: false,
  loading: false,
  error: null,
  data: null
};

const PlantReducer = function (state = initialState, action) {
  switch (action.type) {
    case PLANTS_LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        data: null,
      };
    }    
    case PLANTS_SUCCESS: {
      return {
        ...state,
        success: true,
        data: action.payload,
        loading: false,
        error: null,
      };
    }    
    case PLANTS_ERROR: {
      return {
        success: false,
        loading: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default PlantReducer;
