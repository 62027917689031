
import React, { useEffect } from "react";
import VerifyImagesForm from "./VerifyImagesForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut, getStudentUsers, verifyImages } from "../../../redux/actions/UserActions";
import { getInstitutions } from "../../../redux/actions/InstitutionActions";

export default function VerifyImages() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const usersObj = useSelector((state) => state.user?.data);
  const schoolsData = useSelector((state) => state.institution.data);

  useEffect(() => {
      dispatch(getInstitutions());
  }, []);
  
  const onSearch= (obj) => {
    dispatch(getStudentUsers(2, obj.sch, obj.std, obj.div, obj.roll));   
  };

  const onVerify = (obj) => {
    dispatch(verifyImages(obj)).then((res) => {
      alert("Done.");
      window.location.reload();
    });    
  };

  const logOut = () => {
    dispatch(signOut()).then((res) => {
      navigate("/admin/login");
    });    
  };

  return (
    <div>
      <VerifyImagesForm
        onVerify={onVerify}
        logOut={logOut}
        usersObj={usersObj}
        onSearch={onSearch}
        schoolsData={schoolsData}
      />
    </div>
  );
}
