
import React, { useEffect } from "react";
import RegisterForm from "./RegisterForm";
import { register, REGISTER_ERROR } from "../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getInstitutions } from "../../redux/actions/InstitutionActions";
import { getPlants } from "../../redux/actions/PlantActions";

export default function Register() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const schoolsData = useSelector((state) => state.institution.data);
  const plantsData = useSelector((state) => state.plant.data);
  let loggedUser = localStorage.getItem("user");

  if (loggedUser) {
    navigate('/upload');
  }
  
  useEffect(() => {
      dispatch(getInstitutions());
      dispatch(getPlants());
  }, []);

  const onSubmit = (obj) => {
      dispatch(register(obj)).then((res) => {
        if (res?.type === REGISTER_ERROR) {
          alert(res?.payload?.data?.message);
          navigate("/");
        } else {
          navigate("/thankyou");
        }    
      });    
  };

  return (
    <div>
      <RegisterForm
        onSubmit={onSubmit}
        schoolsData={schoolsData}
        plantsData={plantsData}
      />
    </div>
  );
}
