import {
  Box,
  Button,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const signInSchema = yup.object().shape({
  school: yup.string().required("required"),
  standard: yup.string().required("required"),
  division: yup.string().required("required"),
  rollNumber: yup.string().required("required")
});

const initialValuesSignIn = { 
  school: "",
  rollNumber: "",
  standard: "",
  division: "",
};

const stdOptions = [{label: 10, value: 10}, {label: 9, value: 9}, {label: 8, value: 8}, {label: 7, value: 7}, {label: 6, value: 6}];
const divOptions = [{label: 'A', value: 'A'}, {label: 'B', value: 'B'}, {label: 'C', value: 'C'}, {label: 'D', value: 'D'}];

const SignInForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [schoolsOptions, setSchoolsOptions] = useState([]);

  const populateSchools = () => {
    let schools = props.schoolsData;
    let schoolsOptionItems = [];
    if (schools && schools.length > 0) {
      schools.forEach(function (value, index) {
        schoolsOptionItems.push({
          label: value.institutionName,
          value: value.id,
        });
      });
      setSchoolsOptions(schoolsOptionItems);
    }
  };

  useEffect(() => {
    populateSchools();
  }, [props.schoolsData]);

  const handleFormSubmit = (values) => {
    let obj = {
      school: values.school,
      referenceNumber: values.standard + values.division + values.rollNumber,
    };  
    props.onLogin(obj);
  };
  
  return (
    <Formik
      initialValues={initialValuesSignIn}
      onSubmit={(values, {resetForm}) => {
           handleFormSubmit(values);
           resetForm()
      }}
      validationSchema={signInSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => (
        <div className="container">
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="primary-box">
              <h2 className="title"> LogIn </h2>
              <p className="sub-title"> Please fill in the below details to logIn </p>
              <div className="form">
                <div className="form-content">
                  <div className="form-content__inputs">
                    <label className="label">Select School</label>
                    <div>
                      <TextField
                        id="outlined-select"
                        select
                        name="school"
                        value={values.school}
                        onChange={handleChange}
                        defaultValue=""
                        fullWidth
                        size="small"
                        error={Boolean(touched.school) && Boolean(errors.school)}
                        helperText={touched.school && errors.school}
                      >
                        {schoolsOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                  <div className="form-content__inputs">
                    <label className="label">Select Standard</label>
                    <div>
                      <TextField
                        id="outlined-select"
                        select
                        name="standard"
                        value={values.standard}
                        onChange={handleChange}
                        defaultValue=""
                        fullWidth
                        size="small"
                        error={Boolean(touched.standard) && Boolean(errors.standard)}
                        helperText={touched.standard && errors.standard}
                      >
                        {stdOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div className="form-content">
                  <div className="form-content__inputs">
                    <label className="label">Select your division</label>
                    <div>
                      <TextField
                        id="outlined-select"
                        select
                        name="division"
                        value={values.division}
                        onChange={handleChange}
                        defaultValue=""
                        fullWidth
                        size="small"
                        error={Boolean(touched.division) && Boolean(errors.division)}
                        helperText={touched.division && errors.division}
                      >
                        {divOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                  <div className="form-content__inputs">
                    <label className="label">Enter your roll number</label>
                    <div>
                      <TextField
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rollNumber}
                        name="rollNumber"
                        error={Boolean(touched.rollNumber) && Boolean(errors.rollNumber)}
                        helperText={touched.rollNumber && errors.rollNumber}
                        sx={{ gridColumn: "span 2" }}
                        inputProps={{
                          maxLength: 5,
                        }}
                        fullWidth
                        size="small"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="form-action">
                    <Button
                      type="submit"
                      sx={{
                        width: 247,
                        height: 50,
                        backgroundColor: "#F9A31A",
                        color: "#FFFFFF",
                        "&:hover": { color: "#FFFFFF", backgroundColor: "#F9A31A" }
                      }}
                    >
                      LogIn
                    </Button>
                    <div className="register-link">
                      <span className="txt">Don’t have an Account? </span>
                      <Link to="/register" className="link">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="spacer"></div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default SignInForm;