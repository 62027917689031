import { createTheme } from "@mui/material";

const themeOption  = {
    palette: {
        primary: {
            main: "#9ef01a",
            light: "#3c3c3c",
            dark: "#080808"
        },
        secondary: {
            main: "#e34b25",
            light: "#e86f50",
            dark: "#9e3419"
        },
    },
    typography: {
        fontSize: 18
    },
    components: {
        
    }
}

const appTheme = createTheme(themeOption);

export default appTheme;