import {
  Routes,
  Route,
} from 'react-router-dom';
import Home from "../pages/Home/Home";
import Register from "../pages/Register/Register";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import Thankyou from "../pages/Register/Thankyou";
import SignIn from '../pages/SignIn/SignIn';
import Upload from "../pages/Upload/Upload";
import Certificate from "../pages/Certificate/Certificate";
import VerifyImages from '../pages/Admin/VerifyImages/VerifyImages';
import Login from '../pages/Admin/Login/Login';
import SignOut from '../pages/SignOut/SignOut';


const AppRoutes = () => {  
  return (
      <>
      <Routes>
        <Route path='/'>
          {/* <Route path="/" element={<Navigate to="home" replace />} /> */}
          <Route path='/' element={<Home />} />
          <Route path='register' element={<Register />} />
          <Route path='login' element={<SignIn />} />
          <Route path='thankyou' element={<Thankyou />} />
          <Route path='upload' element={<Upload />} />
          <Route path='certificate' element={<Certificate />} />
          <Route path='admin/login' element={<Login />} />
          <Route path='admin/verifyimages' element={<VerifyImages />} />
          <Route path='signout' element={<SignOut />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
