import {  
  INSTITUES_LOADING,
  INSTITUES_SUCCESS,
  INSTITUES_ERROR,
 
} from "../actions/InstitutionActions";

const initialState = {
  success: false,
  loading: false,
  error: null,
  data: null
};

const InstitutionReducer = function (state = initialState, action) {
  switch (action.type) {
    case INSTITUES_LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        data: null,
      };
    }    
    case INSTITUES_SUCCESS: {
      return {
        ...state,
        success: true,
        data: action.payload,
        loading: false,
        error: null,
      };
    }    
    case INSTITUES_ERROR: {
      return {
        success: false,
        loading: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default InstitutionReducer;
