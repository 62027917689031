import UserService from "../../services/UserService";
import axios from "../../utils/axios";

export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const SIGNIN_LOADING = "SIGNIN_LOADING";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";

export const SIGN_OUT = "SIGN_OUT";

export const UPLOAD_PLANT_LOADING = "UPLOAD_PLANT_LOADING";
export const UPLOAD_PLANT_SUCCESS = "UPLOAD_PLANT_SUCCESS";
export const UPLOAD_PLANT_ERROR = "UPLOAD_PLANT_ERROR";

export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const USERTYPE_LOADING = "USERTYPE_LOADING";
export const USERTYPE_SUCCESS = "USERTYPE_SUCCESS";
export const USERTYPE_ERROR = "USERTYPE_ERROR";

export const VERIFY_LOADING = "VERIFY_LOADING";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_ERROR = "VERIFY_ERROR";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const register = (data) => async (dispatch) => {
  dispatch({
    type: REGISTER_LOADING,
  });
  try {
    let res = await UserService.register(data);

    return dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: REGISTER_ERROR,
      payload: err.response,
    });
  }
};

export const signIn = (data) => async (dispatch) => {
  dispatch({
    type: SIGNIN_LOADING,
  });
  try {
    let res = await UserService.signIn(data);

    if (res && res.data && res.data.token) {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      axios.defaults.headers["content-type"] = "application/json";
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common["Authorization"];
    }

    return dispatch({
      type: SIGNIN_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: SIGNIN_ERROR,
      payload: err.response,
    });
  }
};

export const signOut = () => async (dispatch) => {
  localStorage.clear();
  delete axios.defaults.headers.common["Authorization"];
  return dispatch({
    type: SIGN_OUT,
    payload: null,
  });
};

export const uploadPlantsPictures = (data) => async (dispatch) => {
  dispatch({
    type: UPLOAD_PLANT_LOADING,
  });
  try {
    let res = await UserService.uploadPlantsPictures(data);

    return dispatch({
      type: UPLOAD_PLANT_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: UPLOAD_PLANT_ERROR,
      payload: err.response,
    });
  }
};

export const login = (data) => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
  });
  try {
    let res = await UserService.login(data);
    if (res && res.data && res.data.token) {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      axios.defaults.headers["content-type"] = "application/json";
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common["Authorization"];
    }

    return dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: LOGIN_ERROR,
      payload: err.response,
    });
  }
};

export const getStudentUsers = (type, sch, std, div, roll) => async (dispatch) => {
  dispatch({
    type: USERTYPE_LOADING,
  });
  try {
    let res = await UserService.getStudentUsers(type, sch, std, div, roll);

    return dispatch({
      type: USERTYPE_SUCCESS,
      payload: res.data.users,
    });
  } catch (err) {
    return dispatch({
      type: USERTYPE_ERROR,
      payload: err.response,
    });
  }
};

export const verifyImages = (data) => async (dispatch) => {
  dispatch({
    type: VERIFY_LOADING,
  });
  try {
    let res = await UserService.verifyImages(data);
    return dispatch({
      type: VERIFY_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: VERIFY_ERROR,
      payload: err.response,
    });
  }
};

export const getUserByRefId = (refId) => async (dispatch) => {
  dispatch({
    type: GET_USER_LOADING,
  });
  try {
    let res = await UserService.getUserByRefId(refId);

    return dispatch({
      type: GET_USER_SUCCESS,
      payload: res.data.users,
    });
  } catch (err) {
    return dispatch({
      type: GET_USER_ERROR,
      payload: err.response,
    });
  }
};

