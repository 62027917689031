
import React, { useEffect } from "react";
import CertificateForm from "./CertificateForm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../redux/actions/UserActions";

export default function Upload() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let loggedUser = localStorage.getItem("user");
  
  const logOut = () => {
    dispatch(signOut()).then((res) => {
      navigate("/login");
    });     
  };

  (loggedUser) ?  loggedUser = JSON.parse(loggedUser) : logOut();


  return (
    <div>
      <CertificateForm
        loggedUser={loggedUser}
      />
    </div>
  );
}
