import PlantService from "../../services/PlantService";

export const PLANTS_LOADING = "PLANTS_LOADING";
export const PLANTS_SUCCESS = "PLANTS_SUCCESS";
export const PLANTS_ERROR = "PLANTS_ERROR";

export const getPlants = (data) => async (dispatch) => {
  dispatch({
    type: PLANTS_LOADING,
  });
  try {
    let res = await PlantService.getPlants(data);

    return dispatch({
      type: PLANTS_SUCCESS,
      payload: res.data.plants,
    });
  } catch (err) {
    return dispatch({
      type: PLANTS_ERROR,
      payload: err.response,
    });
  }
};
