
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut} from "../../redux/actions/UserActions";

export default function SignOut() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(signOut()).then((res) => {
        navigate("/login");
        window.location.reload();
      }); 
  }, []);  

//   return (<></>);
}
