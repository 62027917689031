import InstitutionService from "../../services/InstitutionService";

export const INSTITUES_LOADING = "INSTITUES_LOADING";
export const INSTITUES_SUCCESS = "INSTITUES_SUCCESS";
export const INSTITUES_ERROR = "INSTITUES_ERROR";

export const getInstitutions = (data) => async (dispatch) => {
  dispatch({
    type: INSTITUES_LOADING,
  });
  try {
    let res = await InstitutionService.getInstitutions(data);

    return dispatch({
      type: INSTITUES_SUCCESS,
      payload: res.data.institutions,
    });
  } catch (err) {
    return dispatch({
      type: INSTITUES_ERROR,
      payload: err.response,
    });
  }
};
