import {
  Button,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const loginSchema = yup.object().shape({
  username: yup.string().required("required"),
  password: yup.string().required("required")
});

const initialValuesLogin = { 
  username: "",
  password: "",
};

const LoginForm = (props) => {
   
  const handleFormSubmit = (values) => {
    let obj = {
      username: values.username,
      password: values.password,
    };  
    props.onLogin(obj);
  };
  
  return (
    <Formik
      initialValues={initialValuesLogin}
      onSubmit={(values, {resetForm}) => {
           handleFormSubmit(values);
           resetForm()
      }}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => (
        <div className="container">
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <div className="primary-box">
              <h2 className="title"> LogIn </h2>
              <p className="sub-title"> Please fill in the below details to logIn </p>
              <div className="form">
                <div className="form-content">
                <div className="form-content__inputs">
                    <label className="label">User Name</label>
                    <div>
                      <TextField
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.username}
                        name="username"
                        error={Boolean(touched.username) && Boolean(errors.username)}
                        helperText={touched.username && errors.username}
                        sx={{ gridColumn: "span 2" }}
                        fullWidth
                        size="small"
                      />
                    </div>
                  </div>                  
                </div>
                <div className="form-content">                 
                  <div className="form-content__inputs">
                    <label className="label">Password</label>
                    <div>
                      <TextField
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        type="password"
                        error={Boolean(touched.password) && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        sx={{ gridColumn: "span 2" }}
                        fullWidth
                        size="small"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="form-action">
                    <Button
                      type="submit"
                      sx={{
                        width: 247,
                        height: 50,
                        backgroundColor: "#F9A31A",
                        color: "#FFFFFF",
                        "&:hover": { color: "#FFFFFF", backgroundColor: "#F9A31A" }
                      }}
                    >
                      LogIn
                    </Button>
                    <div className="register-link">
                      <span className="txt">Don’t have an Account? </span>
                      <Link to="/register" className="link">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="spacer"></div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default LoginForm;